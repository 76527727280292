class BrandSectionTemplateDefault extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {

		this.$el = $(this);
		if (this.dataset.model) {
			// Padding top
			this.data.paddingTop =
				typeof this.data.paddingTop !== 'undefined'
					? this.data.paddingTop
					: '';

			// Padding bottom
			this.data.paddingBottom =
				this.data.paddingBottom === 'same-as-top'
					? this.data.paddingTop.replace('top', 'bottom')
					: this.data.paddingBottom;

			// Padding left
			this.data.paddingLeft =
				this.data.paddingLeft === 'same-as-top'
					? this.data.paddingTop.replace('top', 'left')
					: this.data.paddingLeft;

			// Padding right
			this.data.paddingRight =
				this.data.paddingRight === 'same-as-top'
					? this.data.paddingTop.replace('top', 'right')
					: this.data.paddingRight;

			// Background Image
			if (
				typeof this.data.backgroundImage.src !== 'undefined' &&
				this.data.backgroundImage.src
			) {
				this.style.backgroundImage = `url(${this.data.backgroundImage.src})`;

				if (this.data.alignBackground) {
					this.style.backgroundPosition = `${this.data.alignBackground} center`;
				}

				if (this.data.scale === 'fit') {
					this.style.backgroundSize = 'cover';
					this.style.backgroundRepeat = 'no-repeat';
				} else {
					this.style.backgroundSize = 'auto';
					this.style.backgroundRepeat = 'repeat';
				}
			}
		}
		const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
		$(document).on('BATComponentsLoaded', async () => {
			if(!loggedIn){
				if($(this).hasClass("hero-section-hog-contest")){
					$(".hero-section-hog-contest .bat-image picture source").remove()
					$(".hero-section-hog-contest .bat-image img").attr("src","/content/dam/glo-it/images/glo-Landing_NON%20loggati_mobile-375x229.png");
					$(".hero-section-hog-contest .bat-headline").html('<h2 style="font-size: 29px !important; "> TANTI EVENTI DA NON PERDERE </h2><br><p style="font-size: 24px;line-height: 23px;"> Vivi emozioni uniche con <b>House of Gloers.</b></p>')
					$(".hero-section-hog-contest .bat-text").remove();
				}else if($(this).hasClass("first-descriptive-section-hog-contest")){
					$(this).remove();
				}else if($(this).hasClass("second-descriptive-section-hog-contest")){
					$(this).remove();
				}

				$('.thr-no-loyalty a[title="ACCEDI"]').on("click", (event)=>{
					event.preventDefault();
					event.stopPropagation();
					window.location.href = '/it/it/myaccount/account/login?fromTHRLanding=true&link='+ "/it/it/house-of-gloers-music-crew";
				})
			}
		})

		
	}


	async setData(){
		this.$el = $(this);
		this.data.loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
		if(this.data.loggedIn){
			const requestCustomerDetails = await Commerce.getCustomerDetails();
			const customer = requestCustomerDetails.data ? requestCustomerDetails.data.customer : '';
		
			if(customer){
				this.data.logged=true
			}
		}else{
			this.data.logged=false
		}
		this.interval = setInterval(()=>{
			this.setCtaLink();
		},1000);
	}

	afterLoad() {
		this.$el = $(this);
		this.setData();
		this.changeContentForHOGContest();
	}

	beforeUpdate() {
		// console.log('SectionTemplateDefault before update');
	}

	renderComponent(data) {
		this.setAttribute('data-model', JSON.stringify(data));
	}

	changeContentForHOGContest(){
		this.$el = $(this);
		
	}

	setCtaLink(){
		this.$el = $(this);
		const loyalty_sections=document.querySelectorAll("[data-component-id = loyalty_section]");
		loyalty_sections.forEach((current_section)=> {
			const cta = current_section.querySelector(".bat-cta span");
			if(cta){
				clearInterval(this.interval);
				if(this.data.logged){
					cta.parentElement.href="/it/it/myaccount/insiders-club";
				}else{
					cta.parentElement.href="/it/it/myaccount/account/login";
				}
			}
			
		});
	}

	afterUpdate() {
		this.$el = $(this);
		this.interval = setInterval(()=>{
			this.setCtaLink();
		},1000);
	}

	unload() {
		// console.log('SectionTemplateDefault after unload');
	}
}

!customElements.get('bat-section-default') &&
	customElements.define('bat-section-default', BrandSectionTemplateDefault);
